<style lang="scss" scoped>
.fdd {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .main {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 12px;
  }
}
</style>

<template>
  <div class="fdd" style="height: 100%; overflow-y: auto">
    <!-- 过滤区域 -->
    <box-item class="box">
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">文件名</div>
            <el-input v-model="v1"></el-input>
          </div>
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

    <div class="main">
      <box-item style="height: 100%;">
        <el-table :data="tableData" stripe style="width: 100%" height="620">
          <el-table-column prop="fileIcon" label="文件" width="120">
            <template slot-scope="scope">
              <i class="iconfont icon-pdf"></i>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名" width="680">
          </el-table-column>
          <el-table-column prop="time" label="生成时间"> </el-table-column>
          <el-table-column prop="size" label="文件大小"> </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="downFile(scope.$index, scope.row)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </box-item>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const v1 = ref(null);
const tableData = ref([]);
const loadData = () => {
  let result = [
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/能源管理体系-实施指南GB-T-29456-2012.pdf`,
      name: "能源管理体系-实施指南GB-T-29456-2012.pdf",
      size: "1.77MB",
      time: "2021-01-16 16:32:11",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/能源管理体系-要求GB-T-23331-2012.pdf`,
      name: "能源管理体系-要求GB-T-23331-2012.pdf",
      size: "0.99MB",
      time: "2021-01-16 16:18:51",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "ISO 50001-2018.pdf",
      size: "1.61MB",
      time: "2021-01-16 16:32:18",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "ISO 50001-2019.pdf",
      size: "1.61MB",
      time: "2021-01-16 16:32:18",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "能源管理体系-实施指南GB-T-29456-2014.pdf",
      size: "1.77MB",
      time: "2021-01-16 16:32:11",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "能源管理体系-要求GB-T-23331-2018.pdf",
      size: "0.99MB",
      time: "2021-01-16 16:18:51",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "ISO 50001-2020.pdf",
      size: "1.51MB",
      time: "2021-01-16 16:32:32",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "ISO 50003-2018.pdf",
      size: "2.61MB",
      time: "2021-01-16 16:52:40",
    },
    {
      filepath: `${process.env.VUE_APP_BASE_ASSETS}file/ISO 50001-2018.pdf`,
      name: "ISO 50002-2018.pdf",
      size: "1.61MB",
      time: "2021-01-16 16:22:10",
    },
  ];
  let res = [];
  if (v1.value) {
    res = result.filter((item) => {
      if (item.name.includes(v1.value)) {
        return item;
      }
    });
  } else {
    res = result;
  }
  tableData.value = res;
};

const downFile = (index, { filepath, name }) => {
  const a = document.createElement("a");
  a.setAttribute("download", name);
  a.setAttribute("href", filepath);
  a.click();
  a.remove();
};

loadData();
</script>
